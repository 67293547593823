"use client"
import {ReactElement, useState, useEffect} from "react";
import {MegaMenuItemProps} from "@/components/mega-menu/MegaMenuItem";
import classNames from "classnames";
import MobileMegaMenuItem from "@/components/mega-menu/MobileMegaMenuItem";
import MegaMenuPromotionItem from "@/components/mega-menu/MegaMenuPromotionItem";
import {Promotion} from "@/domain/Promotion";

export default function MobileMegaMenuWrapper({
	                                              containerClassName,
	                                              items,
	                                              promotion,
	                                              children
                                              }: MobileMegaMenuWrapperProps) {
	const [selectedItem, setSelectedItem] = useState<string | null>(null);
	const [selectedPromotion, setSelectedPromotion] = useState<Promotion | null>(null);

	const onSelect = (title: string | null) => {
		if (title == null) {
			setSelectedItem(null);
			setSelectedPromotion(promotion);
		} else {
			const selected = items.find(item => item.title == title);
			setSelectedItem(selected?.title || null);
			setSelectedPromotion(selected?.promotion || null);
		}
	};

	useEffect(() => {
		setSelectedPromotion(promotion);
	}, [promotion]);

	const onEnter = (parentElement: HTMLElement | HTMLDivElement) => {
		if (!parentElement) return;
		for (let i = 0; i < parentElement.children.length; i++) {
			//parentElement.children.item(i)?.classList.add("group-hover:scale-100");
		}
	};
	const onLeave = (parentElement: HTMLElement | HTMLDivElement) => {
		if (!parentElement) return;
		for (let i = 0; i < parentElement.children.length; i++) {
			//parentElement.children.item(i)?.classList.remove("group-hover:scale-100");
		}
	};

	return (
		<div className={"group relative flex"}
		     onMouseEnter={(e) => onEnter(e.currentTarget)}
		     onTouchStart={(e) => onEnter(e.currentTarget)}>
			{children}
			{
				<div
					onMouseEnter={(e) => onLeave(e.currentTarget.parentElement || e.currentTarget)}
					className={"group/bg absolute w-[400vw] h-[100vh] -ml-[100vw] top-[54px] z-10 rounded-b-xl scale-0 bg-navbar_bg"
						//"group-hover:scale-100"
					}/>}
			<div
				className={classNames("border-t-1 border-[#E8E8E8] absolute -ml-[calc(100vw-32px)] md:-ml-[calc(100vw-52px)] z-20 top-[54px] scale-0 bg-white group-hover:scale-100 gap-1 grid grid-cols-1 w-[100vw]", containerClassName)}>
				<div className={"p-10"}>
					{items.map((item, index) => MobileMegaMenuItem({...item, selectedItem, onSelect}))}
				</div>
				<div className={"bg-[#F4F5F7] w-[100vw] p-10"}>
					<MegaMenuPromotionItem {...selectedPromotion}/>
				</div>
			</div>
		</div>
	)
}

interface MobileMegaMenuWrapperProps {
	items: Array<MegaMenuItemProps>
	children: ReactElement,
	containerClassName?: string,
	mode?: "light" | "dark"
	promotion?: Promotion
}
