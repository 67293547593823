"use client"
import Image from "next/image";
import {HeaderButton} from "@/components/header/HeaderButton";
import MegaMenuWrapper from "@/components/mega-menu/MegaMenuWrapper";
import LanguageMenuWrapper from "@/components/mega-menu/language/LanguageMenuWrapper";
import {useTranslations} from "next-intl";
import {motion, useScroll, useTransform} from "framer-motion";
import {useEffect, useState} from "react";
import {getCurrentPage} from "@/util/PageUtil";
import classNames from "classnames";
import GenericButton from "@/components/generic/GenericButton";
import {PromotionService} from "@/service/PromotionService";
import {Promotion} from "@/domain/Promotion";
import Cookies from "js-cookie";

export default function GenericHeader({
	                                      containerClassName, mode,
	                                      initialColor
                                      }: GenericHeaderProps) {

	const promotionService = new PromotionService();
	const [solutionsPromotion, setSolutionsPromotion] = useState<Promotion | null>(null);
	const [industriesPromotion, setIndustriesPromotion] = useState<Promotion | null>(null);
	const [resourcesPromotion, setResourcesPromotion] = useState<Promotion | null>(null);
	const [companyPromotion, setCompanyPromotion] = useState<Promotion | null>(null);

	useEffect(() => {
		promotionService.getByName("solutions").then(setSolutionsPromotion);
		promotionService.getByName("industries").then(setIndustriesPromotion);
		promotionService.getByName("resources").then(setResourcesPromotion);
		promotionService.getByName("company").then(setCompanyPromotion);
	}, []);

	const t = useTranslations('');
	const {scrollYProgress} = useScroll({});
	const color = useTransform(scrollYProgress, [0, 0.001, 0.1], [initialColor, initialColor, "white"]);
	const [isTransparent, setIsTransparent] = useState(initialColor == "transparent");
	const currentPage = typeof window !== "undefined" ? getCurrentPage(window.location.pathname) : "";
	color.on("change", () => {
		switch (color.get()) {
			case initialColor:
				if (initialColor == "transparent") setIsTransparent(true);
				break;
			default:
				setIsTransparent(false);
				break;
		}
	})
	return (
		<motion.div
			style={{
				backgroundColor: color,
				transition: "background-color 0.2s linear"
			}}
			className={`w-full fixed items-center flex justify-center h-16 z-50 ${containerClassName}`}>
			<nav className={"xl:max-w-[1040px] xl:min-w-[1040px] justify-between flex xl:gap-x-[92.5px]"}>
				<div className={"w-[184px] flex items-center"}>
					<a href={"/"} className={"w-[114px] flex items-center"}>
						<Image
							src={(isTransparent && initialColor != "white") || (isTransparent) ? "/logo/Logo.svg" : "/logo/Logo_black.svg"}
							alt={"Formica Logo"} width={114}
							height={34}/>
					</a>
				</div>
				<nav className={"max-w-fit items-center flex gap-x-6"}>
					{MegaMenuWrapper({
						items: [
							{
								title: t("header.solutions.items.transactionMonitoring"),
								description: t("header.solutions.descriptions.transactionMonitoring"),
								href: "/solutions/transaction-monitoring",
								icon: "transaction-monitoring"
							},
							{
								title: t("header.solutions.items.accountProtection"),
								description: t("header.solutions.descriptions.accountProtection"),
								href: "/solutions/account-protection",
								icon: "account-protection"
							},
							{
								title: t("header.solutions.items.paymentOptimization"),
								description: t("header.solutions.descriptions.paymentOptimization"),
								href: "/solutions/payment-optimization",
								icon: "payment-optimization"
							},
							{
								title: t("header.solutions.items.scamPrevention"),
								description: t("header.solutions.descriptions.scamPrevention"),
								href: "/solutions/scam-prevention",
								icon: "scam-prevention"
							},
							{
								title: t("header.solutions.items.accountOpening"),
								description: t("header.solutions.descriptions.accountOpening"),
								href: "/solutions/account-opening",
								icon: "account-opening"
							},
							{
								title: t("header.solutions.items.amlKyc"),
								description: t("header.solutions.descriptions.amlKyc"),
								href: "/solutions/aml-kyc",
								icon: "aml-and-kyc"
							}
						],
						children: HeaderButton({
							title: t("header.solutions.title"),
							icon: "down_arrow",
							className: classNames("flex h-20 items-center cursor-pointer fill-white", currentPage.includes("solutions") && "!text-formica"),
							mode
						}),
						promotion: solutionsPromotion
					})}
					{MegaMenuWrapper({
						items: [
							{
								title: t("header.industries.items.banking"),
								description: t("header.industries.descriptions.banking"),
								href: "/industries/banking",
								icon: "banking"
							},
							{
								title: t("header.industries.items.travelAndTicketing"),
								description: t("header.industries.descriptions.travelAndTicketing"),
								href: "/industries/travel-and-ticketing",
								icon: "travel-and-ticketing"
							},
							{
								title: t("header.industries.items.paymentFacilitator"),
								description: t("header.industries.descriptions.paymentFacilitator"),
								href: "/industries/payment-facilitator",
								icon: "payment-faciliator-1"
							},
							{
								title: t("header.industries.items.bnpl"),
								description: t("header.industries.descriptions.bnpl"),
								href: "/industries/bnpl",
								icon: "bnpl"
							},
							{
								title: t("header.industries.items.eCommerce"),
								description: t("header.industries.descriptions.eCommerce"),
								href: "/industries/e-commerce",
								icon: "e-commerce"
							}
						],
						children: HeaderButton({
							title: t("header.industries.title"),
							icon: "down_arrow",
							className: classNames("flex h-20 items-center cursor-pointer fill-white", currentPage.includes("industries") && "!text-formica"),
							mode
						}),
						promotion: industriesPromotion
					})}
					{MegaMenuWrapper({
						items: [
							{
								title: t("header.resources.items.blogs"),
								description: t("header.resources.descriptions.blogs"),
								href: "/blog",
								icon: "blogs"
							},
							{
								title: t("header.resources.items.eBooks"),
								description: t("header.resources.descriptions.eBooks"),
								href: "/e-books",
								icon: "ebooks"
							}
						],
						children: HeaderButton({
							title: t("header.resources.title"),
							icon: "down_arrow",
							className: classNames("flex h-20 items-center cursor-pointer fill-white", (currentPage.includes("blog") || currentPage.includes("e-books")) && "!text-formica"),
							mode
						}),
						promotion: resourcesPromotion
					})}
					{MegaMenuWrapper({
						items: [
							{
								title: t("header.company.items.aboutUs"),
								description: t("header.company.descriptions.aboutUs"),
								href: "/company/about-us",
								icon: "about-us",
							},
							{
								title: t("header.company.items.press"),
								description: t("header.company.descriptions.press"),
								href: "/company/press",
								icon: "pressandmedia"
							},
							{
								title: t("header.company.items.references"),
								description: t("header.company.descriptions.references"),
								href: "/company/references",
								icon: "ourreferences"
							}
						],
						children: HeaderButton({
							title: t("header.company.title"),
							icon: "down_arrow",
							className: classNames("flex h-20 items-center cursor-pointer fill-white", currentPage.includes("company") && "!text-formica"),
							mode
						}),
						promotion: companyPromotion
					})}
				</nav>
				<nav className={"items-center flex gap-x-5 pl-1"}>
					{LanguageMenuWrapper({
						items: [
							{
								title: t("header.languages.tr"),
								id: "tr",
								disableArrowIcon: true
							},
							{
								title: t("header.languages.en"),
								id: "en",
								disableArrowIcon: true
							}/*,
       {
        title: t("header.languages.es"),
        id: "es",
        disableArrowIcon: true
       },
       {
        title: t("header.languages.sa"),
        id: "sa",
        disableArrowIcon: true
       }*/
						],
						containerClassName: "grid-cols-none",
						children: HeaderButton({
							className: "flex h-16 items-center cursor-pointer fill-white",
							title: (Cookies.get("NEXT_LOCALE") || "en").toUpperCase(),
							icon: "down_arrow",
							mode
						})
					})}
					<GenericButton title={t("tryNow.buttonTitle")} href={"#contact-us"} componentSize={"sm"}
					               divClassName={"!w-[140px]"}
					               aClassName={"!text-sm !px-0 !w-full"}
					               variant={(mode == "dark" || !isTransparent) ? "darkBlue" : "transparent"}/>
				</nav>
			</nav>
		</motion.div>
	);
}

export interface GenericHeaderProps {
	containerClassName?: string,
	initialColor?: "transparent" | "white",
	mode?: "light" | "dark"
}
