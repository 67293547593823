import classNames from "classnames";
import Icon from "@/components/icon/Icon";
import {Promotion} from "@/domain/Promotion";

export default function MegaMenuPromotionItem({
	                                              name,
	                                              imageUrl,
	                                              imageAlt,
	                                              imageTitle,
	                                              body,
	                                              url,
	                                              resources,
	                                              author,
	                                              createdDate,
	                                              updatedDate,
	                                              containerClassName
                                              }: MegaMenuPromotionItemProps) {

	return (
		<div className={classNames("max-w-[370px] min-w-[270px] w-[304px] xl:w-[370px] h-auto aspect-auto", containerClassName)}>
			<a href={url} title={imageTitle}
			   className={"flex flex-col items-center text-center justify-center hover:cursor-pointer"}>
				<img src={imageUrl} alt={imageTitle} className={"w-full h-40 xl:h-[200px] object-right"}/>
				<div>
					{body && <p className={"text-center text-text_dark font-normal text-sm py-2 underline underline-offset-2"}>
						{body}
						<Icon icon={"down_arrow"} width={16}
						      className={classNames("-rotate-90 text-text_dark")}/>
					</p>}
				</div>
			</a>
		</div>
	)
}


export interface MegaMenuPromotionItemProps extends Promotion {
	containerClassName?: string
}
