import {Promotion} from "@/domain/Promotion";

export class PromotionService {

	private readonly BASE_URL;

	constructor(BASE_URL?: string) {
		this.BASE_URL = BASE_URL || process.env.NEXT_PUBLIC_BASE_URL || "http://localhost:3000";
	}
	public async getByName(name: string): Promise<Promotion> {
		return fetch(`${this.BASE_URL}/api/promotion/${name}`).then(res => res.json())
			.catch((error) => console.log(error));
	}
}
