"use client"
import GenericHeader, {GenericHeaderProps} from "@/components/generic/GenericHeader";
import GenericMobileHeader from "@/components/generic/GenericMobileHeader";
import {useWindowSize} from "@/util/PageUtil";

export default function HeaderContainer({containerClassName, initialColor = "transparent", mode}: GenericHeaderProps) {
	const {width} = useWindowSize();

	return (
		width ? (width >= 1280 ?
			<GenericHeader containerClassName={containerClassName} mode={mode} initialColor={initialColor}/> :
			<GenericMobileHeader containerClassName={containerClassName} initialColor={initialColor} mode={mode}/>) : <></>
	)
}
