"use client"
import {ReactElement, useEffect, useRef, useState} from "react";
import classNames from "classnames";
import LanguageItem, {LanguageItemProps} from "@/components/mega-menu/language/LanguageItemV2";

export default function LanguageMenuWrapper({title, containerClassName, items, children}: LanguageMenuWrapperProps) {
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [isSelected, setIsSelected] = useState(false);

	useEffect(() => {
		function handleClickOutside(event: MouseEvent) {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
				setIsSelected(false);
			}
		}

		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);

	return (
		<div ref={wrapperRef} className={"group relative flex"} onClick={() => setIsSelected(true)}>
			{children}
			{isSelected &&
				<div
					className={classNames("absolute z-20 -left-36 xl:left-0 top-16 grid grid-cols-2 min-w-max", containerClassName)}>
					<div className={"bg-transparent p-0.5"}/>
					{title && <div className={"font-medium text-formica text-lg px-3 w-full col-span-full"}>
						{title}
					</div>}
					<div className={"bg-white rounded-lg"}>
						{items.map((item, index) => LanguageItem({...item}))}
					</div>
				</div>
			}

		</div>
	)
}

interface LanguageMenuWrapperProps {
	title?: string
	items: Array<LanguageItemProps>
	children: ReactElement
	containerClassName?: string
	mode?: "light" | "dark"
}
