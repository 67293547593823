import {useEffect, useLayoutEffect, useState} from "react";

export const getCurrentPage = (pathname: string): string => {
	const path = pathname.split("/");
	return path[1]?.toLowerCase();
}

export function useMediaQuery(query: string) {
	const [matches, setMatches] = useState(false)

	useLayoutEffect(() => {
		const media = window.matchMedia(query)
		if (media.matches !== matches) {
			setMatches(media.matches)
		}
		const listener = () => {
			setMatches(media.matches)
		}
		media.addEventListener('change', listener)
		return () => media.removeEventListener('change', listener)
	}, [matches, query])

	return matches
}

export function useRelativeLeftPosition(ref, parentRef): number {
	const [left, setLeft] = useState(0);

	useLayoutEffect(() => {
		const updateLeftPosition = () => {
			const rect = ref.current?.getBoundingClientRect();
			const parentRect = parentRef.current?.getBoundingClientRect();
			setLeft((rect?.left || 0) - (parentRect?.left || 0));
		};

		updateLeftPosition();
		window.addEventListener('resize', updateLeftPosition);

		return () => {
			window.removeEventListener('resize', updateLeftPosition);
			setLeft(0);
		};
	}, [ref, parentRef]);

	return left;
}

export function useWindowSize() {
	const [windowSize, setWindowSize] = useState<{
		width: number | undefined,
		height: number | undefined,
	}>({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		window.addEventListener("resize", handleResize);

		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return windowSize;
}
