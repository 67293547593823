"use client"
import {ReactElement} from "react";
import MegaMenuItem, {MegaMenuItemProps} from "@/components/mega-menu/MegaMenuItem";
import classNames from "classnames";
import MegaMenuPromotionItem from "@/components/mega-menu/MegaMenuPromotionItem";
import {Promotion} from "@/domain/Promotion";

export default function MegaMenuWrapper({containerClassName, promotion, items, children}: MegaMenuWrapperProps) {

	return (
		<div className={"group flex"}>
			{children}
			<div
				className={classNames("absolute grid items-stretch left-0 z-20 top-16 scale-0 group-hover:scale-100 w-full h-full", containerClassName)}
				style={{ gridTemplateColumns: "1fr auto 1fr" }}>
				<div className={"bg-[#e8e8e8] min-h-[310px]"}/>
				<div className={"w-full flex flex-row justify-center"}>
					<div className={classNames("w-[490px] bg-[#e8e8e8] relative min-h-[310px]")}>
						<MegaMenuPromotionItem {...promotion} containerClassName={"absolute pb-10 top-10 right-[60px]"}/>
					</div>
					<div className={classNames("bg-white w-[710px] px-10 py-10 gap-y-3 gap-x-10 items-start grid grid-cols-2")}>
						{items.map((item, index) => MegaMenuItem({...item}))}
					</div>
				</div>
				<div className={"bg-white min-h-[310px]"}/>
			</div>
		</div>
	)
}

interface MegaMenuWrapperProps {
	items: Array<MegaMenuItemProps>
	children: ReactElement,
	containerClassName?: string,
	mode?: "light" | "dark",
	promotion?: Promotion
}
