import Icon from "@/components/icon/Icon";
import {IconProps} from "react-icomoon";
import {motion, useScroll, useTransform} from "framer-motion";

export function HeaderButton({title, icon, href, showMegaMenu, className, iconProps, mode}: HeaderButtonProps) {
	const {scrollYProgress} = useScroll({});
	const selectedColor = mode == "dark" ? "#333" : "white";
	const color = useTransform(scrollYProgress, [0, 0.001, 0.1], [selectedColor, selectedColor, "#333"]);

	return (
		<motion.a href={href}
		          style={{
			          color,
			          fill: color,
			          transition: "color 0.2s linear"
		          }}
		          className={`flex items-center font-normal text-sm min-w-max h-12 xl:h-16 ${className}`}>
			{title}
			{
				icon && <Icon icon={icon} width={20} {...iconProps}/>
			}
		</motion.a>
	)
}

interface HeaderButtonProps {
	title?: string,
	icon?: string,
	href?: string,
	showMegaMenu?: boolean,
	className?: string
	iconProps?: Partial<IconProps>,
	mode?: "light" | "dark"
}
