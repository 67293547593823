'use client'
import {motion} from "framer-motion";
import classNames from "classnames";
import Icon from "@/components/icon/Icon";

export default function GenericButton({
	                                      href,
	                                      title,
	                                      divClassName,
	                                      aClassName,
	                                      variant,
	                                      icon,
	                                      isSubmit,
	                                      disabled,
	                                      componentSize = "lg"
                                      }: GenericButtonProps) {
	const handleScroll = (e) => {
		const targetStartIndex = e.currentTarget.getAttribute("href").indexOf("#");
		if (targetStartIndex === -1) return;

		e.preventDefault();
		const targetId = e.currentTarget.getAttribute("href").substring(1);
		const targetElement = document.getElementById(targetId);
		if (targetElement) {
			targetElement.scrollIntoView({behavior: "smooth"});
		}
	};

	const getVariantClassNames = () => {
		switch (variant) {
			case "darkBlue":
				return {
					div: "bg-formica_dark hover:bg-formica_dark/90",
					a: "bg-formica_dark text-white fill-white hover:bg-formica_dark/90"
				}
			case "blue":
				return {
					div: "bg-request-white-button-border-gradient",
					a: "bg-request-white-button-gradient text-black fill-black !font-medium"
				}
			case "gradientBlue":
				return {
					div: "bg-blue-button-border-gradient",
					a: "bg-blue-button-gradient text-white fill-white"
				}
			case "transparent":
			default:
				return {
					div: "bg-request-transparent-button-border-gradient",
					a: "bg-request-transparent-button-gradient text-white fill-white"
				}
		}
	}

	const getComponentSize = () => {
		switch (componentSize) {
			case "xs":
				return "h-2 md:h-7 text-xs md:text-sm p-3 px-3"
			case "sm":
				return "text-xs md:text-lg h-[34px] md:h-[34px] px-4  md:p-4 md:px-6"
			default:
			case "lg":
				return "text-base md:text-lg h-10 md:h-10 p-4 px-6"
		}
	}
	return (
		<motion.div
			className={classNames(`flex justify-center items-center p-[2px] rounded-full shadow-header-button`,
				variant && getVariantClassNames().div,
				divClassName)}>
			{isSubmit ?
				<motion.button
					type={"submit"}
					disabled={disabled || false}
					className={classNames(`flex flex-row items-center ${icon ? "justify-between gap-x-2" : "justify-center"} rounded-full text-center font-normal min-w-max`, getComponentSize(),
						variant && getVariantClassNames().a,
						aClassName)}>
					{title}
					{icon && <Icon icon={icon} width={18} height={18}/>
					}
				</motion.button> : <motion.a
					onClick={handleScroll}
					href={href}
					//@ts-ignore
					disabled={disabled || false}
					type={isSubmit && "submit"}
					className={classNames(`flex flex-row items-center ${icon ? "justify-between gap-x-2" : "justify-center"} rounded-full text-center font-normal min-w-max`, getComponentSize(),
						variant && getVariantClassNames().a,
						aClassName)}>
					{title}
					{icon && <Icon icon={icon} width={18} height={18}/>
					}
				</motion.a>
			}
		</motion.div>
	)
}

interface GenericButtonProps {
	href?: string,
	title?: string
	divClassName?: string
	aClassName?: string
	variant?: "transparent" | "blue" | "darkBlue" | "gradientBlue",
	icon?: string,
	isSubmit?: boolean
	disabled?: boolean
	componentSize?: "sm" | "xs" | "lg"
}
