import Image from "next/image";
import Icon from "@/components/icon/Icon";
import {MegaMenuItemProps} from "@/components/mega-menu/MegaMenuItem";
import classNames from "classnames";

export default function MobileMegaMenuItem({
	                                           icon,
	                                           description,
	                                           title,
	                                           href,
	                                           items,
	                                           disableArrowIcon,
	                                           selectedItem,
	                                           onSelect,
	                                           containerClassName
                                           }: MegaMenuItemProps) {
	const onClick = () => {
		if (selectedItem == title) {
			onSelect && onSelect(null)
			return
		}
		onSelect && title && onSelect(title)
	}
	return (
		<div key={title}>
			<a
				className={classNames("flex flex-row w-full xl:min-w-52 py-3 xl:mr-14 pr-5 cursor-pointer gap-5 min-w-fit items-center justify-between rounded-lg", containerClassName)}
				href={href} key={title} onClick={onClick}>
				<div className={"flex flex-row items-start gap-x-2"}>
					{icon &&
						<Icon icon={icon} width={20} size={20} className={"text-formica h-5 w-5 min-w-[20px]"}/>}
					<div className={"flex flex-col gap-x-1"}>
						{title && <p
							className={classNames("font-medium text-sm", selectedItem == title ? "text-formica" : "text-text_dark_blue")}>
							{title}
							{!disableArrowIcon && <Icon icon={"down_arrow"} width={20}
							                            className={classNames("font-medium", selectedItem == title ? "text-formica" : "text-text_dark_blue")}/>}
						</p>}
						{description && <p className={"font-light text-xs text-text_light overflow-ellipsis"}>
							{description}
						</p>}
					</div>
				</div>
			</a>
			{selectedItem == title && items &&
				<div className={"flex flex-col gap-y-3 pr-3 text-text_light"}>
					{items.map((item, index) =>
						<a key={index}
						   className={classNames("flex flex-row items-start gap-x-2")}
						   href={item.href}>
							<Image src={"/mega-menu-icon/mobile/mobile-megamenu-left-arrow.svg"} alt={"Left Arrow"} width={20}
							       height={15}/>
							<div className={"flex flex-col gap-y-0.5"}>
								<div className={"flex flex-row gap-x-2"}>
									{item.icon && <Icon icon={item.icon} className={"text-formica"} width={12}/>}
									{item.title && <p className={"font-normal text-xs"}>
										{item.title}
									</p>}
								</div>
								{item.description && <p className={"font-light text-xs text-text_light"}>
									{item.description}
								</p>}
							</div>
						</a>)}
				</div>}
		</div>
	)
}
